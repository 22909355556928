// router.js
import { createRouter, createWebHistory } from 'vue-router'
import YouthAnniv from './views/YouthAnniv.vue'

const routes = [
  { path: '/', component: YouthAnniv },
  { path: '/youth-anniversary', component: YouthAnniv },

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
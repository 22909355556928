<script setup>
import { ref } from 'vue'

const name = ref('');
const phone = ref('');
const isSuccess = ref(false);
const isError = ref(false);
const errorMsg = ref('');
const isLoading = ref(false);

const submit = () => {
  isLoading.value = true;
  if (name.value === '' || phone.value === '') {
    errorMsg.value = 'Nama dan Nomor Whatsapp harus diisi'
    isError.value = true;
    isLoading.value = false;
    return;
  }


  const phoneRegex = /^[0-9]+$/;
  if (!phoneRegex.test(phone.value)) {
    errorMsg.value = 'Nomor Whatsapp harus angka'
    isError.value = true;
    isLoading.value = false;
    return;
  }


  fetch("https://api.youthmultiply.com/ticket", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name: name.value,
        phone: phone.value,
      }),
    }).then(async (response) => {
      let json = await response.json();

        if (response.status === 201) {
          //SUCCESS
          name.value = '';
          phone.value = '';
          isSuccess.value = true;
          isError.value = false;
          isLoading.value = false;
        } else {
          //FAIL
          name.value = '';
          phone.value = '';
          errorMsg.value = json.error
          isError.value = true;
          isLoading.value = false;
        }
    }).catch(() => {
        name.value = '';
        phone.value = '';
        errorMsg.value = 'Something went wrong'
        isError.value = true;
        isLoading.value = false;
      })
      .finally(() => {
        isLoading.value = false;

        // Reset form and hide result message after 5 seconds
        // setTimeout(() => {
        //   result.classList.add("hidden");
        // }, 5000);
      });
}
</script>


<template>
    <div section-scroll='0' class="gc_scroll_wrap">
        <!-- Header Wrapper End -->
        <!-- GC Slider Wrapper Start -->
        <div class="GC_slider_Wrappwer">
          <div id="rev_slider_348_1_wrapper" class="rev_slider_wrapper fullscreen-container" data-alias="overexposure"
            data-source="gallery" style="background:transparent;padding:0px;">
            <div id="rev_slider_348_1" class="rev_slider fullscreenbanner" data-version="5.4.3.3">
              <ul> <!-- SLIDE  -->
                <li data-index="rs-968" data-transition="brightnesscross" data-slotamount="default" data-hideafterloop="0"
                  data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="default"
                  data-thumb="" data-rotate="0" data-saveperformance="off" data-title="Slide" data-param1="" data-param2=""
                  data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9=""
                  data-param10="" data-description="">
                  <!-- MAIN IMAGE -->
                  <img src="../assets/images/header/ym.jpg" alt="" data-lazyload="" data-bgposition="center center"
                    data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="6" class="rev-slidebg" data-no-retina style="width: 100%;
  height: auto;">
                  
                </li>
                
              </ul>
              <div class="tp-bannertimer tp-bottom" style="display:none;"></div>
            </div>
          </div><!-- END REVOLUTION SLIDER -->
        </div>
        <!-- GC Slider Wrapper End -->
        <!-- GC Event&Audio Player Wrapper Start -->
        <div class="gc_event_main_wrapper">
          <div class="container">
            <div class="gc_player_center_section_wrapper">
              <div class="gc_event_sec_wrapper">
                <div class="row">

                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <h1>Youth Service Special Anniversary</h1>
                      <h4 style="padding-top:10px">29 September 2024 | 11:00 - 12:30</h4>
                      <h4 style="padding-top:10px">@Upper Room GBT Firman Kudus</h4>
                      <h4 style="padding-top:10px;padding-bottom:15px">Dresscode : Formal Attire</h4>
                    <br>
                    <div v-if="isSuccess">
                      <div class="alert alert-success">
                        Pendaftaran Berhasil, See You at Church :)
                      </div>
                    </div>
                    <div v-if="isError">
                      <div class="alert alert-danger">
                        Pendaftaran Gagal - {{ errorMsg }}
                      </div>
                    </div>
                    <div>
                      <div class="row">
                        <div class="col-md-3">

                        </div>
                        <div class="col-md-6"  v-if="!isSuccess">
                          <label for="">Nama</label>
                          <input type="text" v-model="name" class='form-control w-50' required>
                          <br>
                          <label for="">Nomor Whatsapp</label>
                          <input type="text" v-model="phone" class='form-control w-50' required>
                          <br>
                          <a @click="submit" class="btn btn-primary btn-lg" v-if="!isLoading">Submit</a>
                          <button v-else class="btn btn-primary btn-lg" disabled>Loading...</button>
                        </div>
                        
                      </div>
                      
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>
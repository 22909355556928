import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import './assets/css/animate.css'
import './assets/css/bootstrap.css'
import './assets/css/font-awesome.css'
import './assets/css/owl.carousel.css'
import './assets/css/owl.theme.default.css'
import './assets/css/magnific-popup.css'
import './assets/css/fonts.css'
import './assets/css/reset.css'
import './assets/css/style.css'


// createApp(App).mount('#app')
const app = createApp(App)
app.use(router) // Use the router
app.mount('#app')
